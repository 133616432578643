<template>
  <div class="container">

  </div>
</template>

<script>
  class send{
    constructor(x,y){
      console.log(x,y)
    }
  }
  let res = new send('1','2');
  console.log(res)
export default {
  data() {
    return {
      code:'',
      token:'',
    };
  },

  created() {

  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped lang='less'>

</style>